/* $Widget
 ------------------------------------------*/
.ngx-datatable.material {
  padding: 0;
  margin: calc($gutter/3);
  @include mat-elevation(2);
  @include mat-elevation-transition;
  display: block;
  position: relative;
  border-radius: $border-radius-base;
  &.fullscreen {
    position: absolute !important;
    height: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.striped {
    .datatable-row-odd {
      background: mat-color($background, 'hover');
    }
  }
  &.scroll-vertical {
    height: 70vh;
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {
      //&.active,
      //&.active .datatable-row-group {
      //  @include _mat-toolbar-color($primary);
      //}

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($primary), 2%);
        color: #FFF;

      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($primary), 4%);
        color: #FFF;
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($background, 'hover'), 2%);;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($background, 'hover'), 2%);;
      }

      //&.active,
      //&.active .datatable-row-group {
      //  @include _mat-toolbar-color($primary);
      //}

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($primary), 2%);
        color: #FFF;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($primary), 4%);
        color: #FFF;
      }
    }
  }

  /**
   * Shared Styles
   */
  .empty-row{
     padding: .85rem 1.2rem;
  }

  .loading-row{
     padding: .85rem 1.2rem;
  }

  /**
   * Header Styles
   */
  .datatable-header {
    border-bottom: 1px solid mat-color($foreground, divider);

    .datatable-header-cell {
      padding: .85rem 1.2rem;
      color: rgba(mat-color($foreground, base), 0.87);
      vertical-align: middle;

      &.longpress {
        color:#00E676;
      }

      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }
    }

    .resize-handle {
      border-right:solid 1px mat-color($foreground, divider);
    }
  }

  /**
   * Body Styles
   */
  .datatable-body {
    .datatable-row-detail {
      background: #f5f5f5;
      padding: 10px;
    }

    .datatable-body-row {
      .datatable-body-cell {
        padding: .85rem 1.2rem;
        box-sizing: border-box;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        -webkit-box-pack: start;
        -webkit-box-align: center;
        .datatable-body-cell-label {
          width: 100%;
        }
      }
    }

    .progress-linear {
      .container {
        background-color: rgb(170,209,249);

        .bar {
          background-color: rgb(16,108,200);
        }
      }
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid mat-color($foreground, divider);

    .datatable-pager {
      li {
        vertical-align: middle;

        &.disabled a{
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important;
        }

        &.active a{
          background: mat-color($primary);
          color: white;
          font-weight: $font-weight-base;
        }
      }
    }
  }
}

.app-dark .ngx-datatable.material {
  background: mat-color($dark-background, card);
  color: mat-color($dark-foreground, base);
  &.striped {
    .datatable-row-odd {
      background: mat-color($dark-background, 'hover');
    }
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {
      //&.active,
      //&.active .datatable-row-group {
      //  @include _mat-toolbar-color($dark-primary);
      //}

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 2%);
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 4%);
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($dark-background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($dark-background, 'hover'), 2%);;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($dark-background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($dark-background, 'hover'), 2%);;
      }

      //&.active,
      //&.active .datatable-row-group {
      //  @include _mat-toolbar-color($dark-primary);
      //}

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 2%);
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat-color($dark-primary), 4%);
      }
    }
  }

  .datatable-header .datatable-row-left, .datatable-body .datatable-row-left,
  .datatable-header .datatable-row-right, .datatable-body .datatable-row-right {
    background-color: rgba(73, 73, 73, 1);
  }

  /**
   * Header Styles
   */
  .datatable-header {
    border-bottom: 1px solid mat-color($dark-foreground, divider);

    .datatable-header-cell {
      color: mat-color($dark-foreground, base);
    }

    .resize-handle {
      border-right:solid 1px mat-color($dark-foreground, divider);
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid mat-color($dark-foreground, divider);

    .datatable-pager {
      li {
        &.disabled a{
          color: rgba(255, 255, 255, 0.26) !important;
        }
        a {
          color: mat-color($dark-foreground, base);
        }
        &.active a{
          background: mat-color($dark-primary);
        }
      }
    }
  }
}

[dir=rtl] {
  .ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: right;
  }
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: right;
  }
  .ngx-datatable.material .empty-row{
     text-align: right;
  }
  .ngx-datatable.material .loading-row{
     text-align: right;
  }
  .ngx-datatable .datatable-footer .datatable-pager {
    text-align: left;
  }
}


.sb-tag {
  border-radius: 3px;
  font-weight: 600;
  padding: 0 5px;
  border: 1px solid #ddd;
}

.sb-tag-accepted {
  background-color: #3f51b5;
  color: white;
}

.sb-tag-pending, .sb-tag-processing {
  background-color: #7f8c8d;
  color: white;
}

.sb-tag-warning {
  background-color: #e67e22;
  color: white;
}

.sb-tag-reconfirm {
  background-color: #8e44ad;
  color: white;
}

.sb-tag-success {
  background-color: #2ecc71;
  color: white;
}

.sb-tag-danger {
  background-color: #ed6a5e;
  color: white;
}

.sb-tag-overdue {
  background-color: #ed6a5e;
  color: white;
}
