@font-face {
  font-family: 'pe-icon-set-weather';
  src: url("../fonts/pe-icon-set-weather.eot?nlruav");
  src: url("../fonts/pe-icon-set-weather.eot?nlruav#iefix") format("embedded-opentype"), url("../fonts/pe-icon-set-weather.ttf?nlruav") format("truetype"), url("../fonts/pe-icon-set-weather.woff?nlruav") format("woff"), url("../fonts/pe-icon-set-weather.svg?nlruav#pe-icon-set-weather") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="pe-is-w-"], [class*=" pe-is-w-"] {
  display: inline-block;
  font-family: 'pe-icon-set-weather';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pe-is-w-blizzard-f:before {
  content: "\e900";
}

.pe-is-w-blizzard:before {
  content: "\e901";
}

.pe-is-w-cloud-down-f:before {
  content: "\e902";
}

.pe-is-w-cloud-down:before {
  content: "\e903";
}

.pe-is-w-cloud-refresh-f:before {
  content: "\e904";
}

.pe-is-w-cloud-refresh:before {
  content: "\e905";
}

.pe-is-w-cloud-up-f:before {
  content: "\e906";
}

.pe-is-w-cloud-up:before {
  content: "\e907";
}

.pe-is-w-compass-e-f:before {
  content: "\e908";
}

.pe-is-w-compass-e:before {
  content: "\e909";
}

.pe-is-w-compass-f:before {
  content: "\e90a";
}

.pe-is-w-compass-n-f:before {
  content: "\e90b";
}

.pe-is-w-compass-n:before {
  content: "\e90c";
}

.pe-is-w-compass-s-f:before {
  content: "\e90d";
}

.pe-is-w-compass-s:before {
  content: "\e90e";
}

.pe-is-w-compass-w-f:before {
  content: "\e90f";
}

.pe-is-w-compass-w:before {
  content: "\e910";
}

.pe-is-w-compass:before {
  content: "\e911";
}

.pe-is-w-degree-celsius:before {
  content: "\e912";
}

.pe-is-w-degree-fahrenheit:before {
  content: "\e913";
}

.pe-is-w-drizzle-f:before {
  content: "\e914";
}

.pe-is-w-drizzle:before {
  content: "\e915";
}

.pe-is-w-drop-cloud-f:before {
  content: "\e916";
}

.pe-is-w-drop-cloud:before {
  content: "\e917";
}

.pe-is-w-drop-f:before {
  content: "\e918";
}

.pe-is-w-drop-percentage-f:before {
  content: "\e919";
}

.pe-is-w-drop-percentage:before {
  content: "\e91a";
}

.pe-is-w-drop:before {
  content: "\e91b";
}

.pe-is-w-drops-f:before {
  content: "\e91c";
}

.pe-is-w-drops:before {
  content: "\e91d";
}

.pe-is-w-eclipse-1-f:before {
  content: "\e91e";
}

.pe-is-w-eclipse-1:before {
  content: "\e91f";
}

.pe-is-w-eclipse-2-f:before {
  content: "\e920";
}

.pe-is-w-eclipse-2:before {
  content: "\e921";
}

.pe-is-w-eclipse-3-f:before {
  content: "\e922";
}

.pe-is-w-eclipse-3:before {
  content: "\e923";
}

.pe-is-w-eclipse-4-f:before {
  content: "\e924";
}

.pe-is-w-eclipse-4:before {
  content: "\e925";
}

.pe-is-w-fog-1-f:before {
  content: "\e926";
}

.pe-is-w-fog-1:before {
  content: "\e927";
}

.pe-is-w-fog-2-f:before {
  content: "\e928";
}

.pe-is-w-fog-2:before {
  content: "\e929";
}

.pe-is-w-fog-3-f:before {
  content: "\e92a";
}

.pe-is-w-fog-3:before {
  content: "\e92b";
}

.pe-is-w-fog-4-f:before {
  content: "\e92c";
}

.pe-is-w-fog-4:before {
  content: "\e92d";
}

.pe-is-w-full-moon-1-f:before {
  content: "\e92e";
}

.pe-is-w-full-moon-1:before {
  content: "\e92f";
}

.pe-is-w-full-moon-2-f:before {
  content: "\e930";
}

.pe-is-w-full-moon-2:before {
  content: "\e931";
}

.pe-is-w-full-moon-3-f:before {
  content: "\e932";
}

.pe-is-w-full-moon-3:before {
  content: "\e933";
}

.pe-is-w-hail-1-f:before {
  content: "\e934";
}

.pe-is-w-hail-1:before {
  content: "\e935";
}

.pe-is-w-hail-2-f:before {
  content: "\e936";
}

.pe-is-w-hail-2:before {
  content: "\e937";
}

.pe-is-w-hail-day-1-f:before {
  content: "\e938";
}

.pe-is-w-hail-day-1:before {
  content: "\e939";
}

.pe-is-w-hail-day-2-f:before {
  content: "\e93a";
}

.pe-is-w-hail-day-2:before {
  content: "\e93b";
}

.pe-is-w-hail-full-moon-1-f:before {
  content: "\e93c";
}

.pe-is-w-hail-full-moon-1:before {
  content: "\e93d";
}

.pe-is-w-hail-full-moon-2-f:before {
  content: "\e93e";
}

.pe-is-w-hail-full-moon-2:before {
  content: "\e93f";
}

.pe-is-w-hail-night-1-f:before {
  content: "\e940";
}

.pe-is-w-hail-night-1:before {
  content: "\e941";
}

.pe-is-w-hail-night-2-f:before {
  content: "\e942";
}

.pe-is-w-hail-night-2:before {
  content: "\e943";
}

.pe-is-w-heavy-hail-day-f:before {
  content: "\e944";
}

.pe-is-w-heavy-hail-day:before {
  content: "\e945";
}

.pe-is-w-heavy-hail-f:before {
  content: "\e946";
}

.pe-is-w-heavy-hail-full-moon-f:before {
  content: "\e947";
}

.pe-is-w-heavy-hail-full-moon:before {
  content: "\e948";
}

.pe-is-w-heavy-hail-night-f:before {
  content: "\e949";
}

.pe-is-w-heavy-hail-night:before {
  content: "\e94a";
}

.pe-is-w-heavy-hail:before {
  content: "\e94b";
}

.pe-is-w-heavy-rain-1-f:before {
  content: "\e94c";
}

.pe-is-w-heavy-rain-1:before {
  content: "\e94d";
}

.pe-is-w-heavy-rain-2-f:before {
  content: "\e94e";
}

.pe-is-w-heavy-rain-2:before {
  content: "\e94f";
}

.pe-is-w-heavy-rain-day-f:before {
  content: "\e950";
}

.pe-is-w-heavy-rain-day:before {
  content: "\e951";
}

.pe-is-w-heavy-rain-full-moon-f:before {
  content: "\e952";
}

.pe-is-w-heavy-rain-full-moon:before {
  content: "\e953";
}

.pe-is-w-heavy-rain-night-f:before {
  content: "\e954";
}

.pe-is-w-heavy-rain-night:before {
  content: "\e955";
}

.pe-is-w-mist:before {
  content: "\e956";
}

.pe-is-w-mix-rainfall-1-f:before {
  content: "\e957";
}

.pe-is-w-mix-rainfall-1:before {
  content: "\e958";
}

.pe-is-w-mix-rainfall-2-f:before {
  content: "\e959";
}

.pe-is-w-mix-rainfall-2:before {
  content: "\e95a";
}

.pe-is-w-moon-1-f:before {
  content: "\e95b";
}

.pe-is-w-moon-1:before {
  content: "\e95c";
}

.pe-is-w-moon-2-f:before {
  content: "\e95d";
}

.pe-is-w-moon-2:before {
  content: "\e95e";
}

.pe-is-w-moon-3-f:before {
  content: "\e95f";
}

.pe-is-w-moon-3:before {
  content: "\e960";
}

.pe-is-w-moon-4-f:before {
  content: "\e961";
}

.pe-is-w-moon-4:before {
  content: "\e962";
}

.pe-is-w-moon-first-quarter-f:before {
  content: "\e963";
}

.pe-is-w-moon-horizon-f:before {
  content: "\e964";
}

.pe-is-w-moon-horizon:before {
  content: "\e965";
}

.pe-is-w-moon-last-quarter-f:before {
  content: "\e966";
}

.pe-is-w-moon-sea-f:before {
  content: "\e967";
}

.pe-is-w-moon-sea:before {
  content: "\e968";
}

.pe-is-w-moon-waning-crescent-f:before {
  content: "\e969";
}

.pe-is-w-moon-waning-gibbous-f:before {
  content: "\e96a";
}

.pe-is-w-moon-waxing-crescent-f:before {
  content: "\e96b";
}

.pe-is-w-moon-waxing-gibbous-f:before {
  content: "\e96c";
}

.pe-is-w-mostly-cloudy-1-f:before {
  content: "\e96d";
}

.pe-is-w-mostly-cloudy-1:before {
  content: "\e96e";
}

.pe-is-w-mostly-cloudy-2-f:before {
  content: "\e96f";
}

.pe-is-w-mostly-cloudy-2:before {
  content: "\e970";
}

.pe-is-w-partly-cloudy-1-f:before {
  content: "\e971";
}

.pe-is-w-partly-cloudy-1:before {
  content: "\e972";
}

.pe-is-w-partly-cloudy-2-f:before {
  content: "\e973";
}

.pe-is-w-partly-cloudy-2:before {
  content: "\e974";
}

.pe-is-w-partly-cloudy-3-f:before {
  content: "\e975";
}

.pe-is-w-partly-cloudy-3:before {
  content: "\e976";
}

.pe-is-w-rain-1-f:before {
  content: "\e977";
}

.pe-is-w-rain-1:before {
  content: "\e978";
}

.pe-is-w-rain-and-snow-f:before {
  content: "\e979";
}

.pe-is-w-rain-and-snow:before {
  content: "\e97a";
}

.pe-is-w-rain-day-f:before {
  content: "\e97b";
}

.pe-is-w-rain-day:before {
  content: "\e97c";
}

.pe-is-w-rain-full-moon-f:before {
  content: "\e97d";
}

.pe-is-w-rain-full-moon:before {
  content: "\e97e";
}

.pe-is-w-rain-night-f:before {
  content: "\e97f";
}

.pe-is-w-rain-night:before {
  content: "\e980";
}

.pe-is-w-severe-thunderstorm-f:before {
  content: "\e981";
}

.pe-is-w-severe-thunderstorm:before {
  content: "\e982";
}

.pe-is-w-snow-day-1-f:before {
  content: "\e983";
}

.pe-is-w-snow-day-1:before {
  content: "\e984";
}

.pe-is-w-snow-day-2-f:before {
  content: "\e985";
}

.pe-is-w-snow-day-2:before {
  content: "\e986";
}

.pe-is-w-snow-day-3-f:before {
  content: "\e987";
}

.pe-is-w-snow-day-3:before {
  content: "\e988";
}

.pe-is-w-snow-f:before {
  content: "\e989";
}

.pe-is-w-snow-full-moon-1-f:before {
  content: "\e98a";
}

.pe-is-w-snow-full-moon-1:before {
  content: "\e98b";
}

.pe-is-w-snow-full-moon-2-f:before {
  content: "\e98c";
}

.pe-is-w-snow-full-moon-2:before {
  content: "\e98d";
}

.pe-is-w-snow-full-moon-3-f:before {
  content: "\e98e";
}

.pe-is-w-snow-full-moon-3:before {
  content: "\e98f";
}

.pe-is-w-snow-night-1-f:before {
  content: "\e990";
}

.pe-is-w-snow-night-1:before {
  content: "\e991";
}

.pe-is-w-snow-night-2-f:before {
  content: "\e992";
}

.pe-is-w-snow-night-2:before {
  content: "\e993";
}

.pe-is-w-snow-night-3-f:before {
  content: "\e994";
}

.pe-is-w-snow-night-3:before {
  content: "\e995";
}

.pe-is-w-snow:before {
  content: "\e996";
}

.pe-is-w-snowflake:before {
  content: "\e997";
}

.pe-is-w-sun-1-f:before {
  content: "\e998";
}

.pe-is-w-sun-1:before {
  content: "\e999";
}

.pe-is-w-sun-2-f:before {
  content: "\e99a";
}

.pe-is-w-sun-2:before {
  content: "\e99b";
}

.pe-is-w-sun-horizon-1-f:before {
  content: "\e99c";
}

.pe-is-w-sun-horizon-1:before {
  content: "\e99d";
}

.pe-is-w-sun-horizon-2-f:before {
  content: "\e99e";
}

.pe-is-w-sun-horizon-2:before {
  content: "\e99f";
}

.pe-is-w-sunrise-f:before {
  content: "\e9a0";
}

.pe-is-w-sunrise:before {
  content: "\e9a1";
}

.pe-is-w-sunset-f:before {
  content: "\e9a2";
}

.pe-is-w-sunset:before {
  content: "\e9a3";
}

.pe-is-w-thermometer-1-f:before {
  content: "\e9a4";
}

.pe-is-w-thermometer-1:before {
  content: "\e9a5";
}

.pe-is-w-thermometer-2-f:before {
  content: "\e9a6";
}

.pe-is-w-thermometer-2:before {
  content: "\e9a7";
}

.pe-is-w-thermometer-3-f:before {
  content: "\e9a8";
}

.pe-is-w-thermometer-3:before {
  content: "\e9a9";
}

.pe-is-w-thermometer-4-f:before {
  content: "\e9aa";
}

.pe-is-w-thermometer-4:before {
  content: "\e9ab";
}

.pe-is-w-thermometer-5-f:before {
  content: "\e9ac";
}

.pe-is-w-thermometer-5:before {
  content: "\e9ad";
}

.pe-is-w-thunderbolt-1-f:before {
  content: "\e9ae";
}

.pe-is-w-thunderbolt-1:before {
  content: "\e9af";
}

.pe-is-w-thunderbolt-2-f:before {
  content: "\e9b0";
}

.pe-is-w-thunderbolt-2:before {
  content: "\e9b1";
}

.pe-is-w-thunderstorm-day-1-f:before {
  content: "\e9b2";
}

.pe-is-w-thunderstorm-day-1:before {
  content: "\e9b3";
}

.pe-is-w-thunderstorm-day-2-f:before {
  content: "\e9b4";
}

.pe-is-w-thunderstorm-day-2:before {
  content: "\e9b5";
}

.pe-is-w-thunderstorm-f:before {
  content: "\e9b6";
}

.pe-is-w-thunderstorm-full-moon-1-f:before {
  content: "\e9b7";
}

.pe-is-w-thunderstorm-full-moon-1:before {
  content: "\e9b8";
}

.pe-is-w-thunderstorm-full-moon-2-f:before {
  content: "\e9b9";
}

.pe-is-w-thunderstorm-full-moon-2:before {
  content: "\e9ba";
}

.pe-is-w-thunderstorm-night-1-f:before {
  content: "\e9bb";
}

.pe-is-w-thunderstorm-night-1:before {
  content: "\e9bc";
}

.pe-is-w-thunderstorm-night-2-f:before {
  content: "\e9bd";
}

.pe-is-w-thunderstorm-night-2:before {
  content: "\e9be";
}

.pe-is-w-thunderstorm:before {
  content: "\e9bf";
}

.pe-is-w-tornado-1:before {
  content: "\e9c0";
}

.pe-is-w-tornado-2:before {
  content: "\e9c1";
}

.pe-is-w-umbrella-f:before {
  content: "\e9c2";
}

.pe-is-w-umbrella:before {
  content: "\e9c3";
}

.pe-is-w-wind-2:before {
  content: "\e9c4";
}

.pe-is-w-wind-cloud:before {
  content: "\e9c5";
}

.pe-is-w-wind-cone-f:before {
  content: "\e9c6";
}

.pe-is-w-wind-cone:before {
  content: "\e9c7";
}

.pe-is-w-wind-day:before {
  content: "\e9c8";
}

.pe-is-w-wind-full-moon:before {
  content: "\e9c9";
}

.pe-is-w-wind-moon:before {
  content: "\e9ca";
}

.pe-is-w-wind-night:before {
  content: "\e9cb";
}

.pe-is-w-wind-sun:before {
  content: "\e9cc";
}

.pe-is-w-wind-turbine-f:before {
  content: "\e9cd";
}

.pe-is-w-wind-turbine:before {
  content: "\e9ce";
}

.pe-is-w-wind:before {
  content: "\e9cf";
}
