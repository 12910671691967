/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@import '~bootstrap/scss/bootstrap.scss';
@import url('//fonts.googleapis.com/icon?family=Material+Icons');
@import '~bootstrap/scss/bootstrap.scss';
@import "assets/fonts/inter/inter.css";
@import '~quill/dist/quill.snow.css';

@include mat-core();

$primary: mat-palette($mat-indigo, 500);
$accent: mat-palette($mat-green, 500, A200, A400);
$warn: mat-palette($mat-red, 500);

$theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($theme);

body {
  margin: 0;
}

h1, h2, h3, h4 {
  font-weight: normal;
}

$custom-typography: mat-typography-config(
  $font-family: 'Inter, sans-serif'
);
@include angular-material-typography($custom-typography);

.status-icon {
  height: 22px;
  width: 22px;
}

.ratemeal {
  padding: 5px 0px 10px 0px
}

.ratemeal i {
  font-size: 26px;
  display: inline-block;
  padding: 0px 2px;
  color: #4a4a4a
}

.ratemeal i.active {
  color: #ED6A5E;
}

a {
  text-decoration: none
}

a:link {
  color: #3f51b5;
}

a:hover {
  color: #ED6A5E !important;
}

a:visited {
  color: #3f51b5;
}

.text-green {
  color: #5EC7A6;
}

.text-orange {
  color: #ED6A5E;
}

.mat-dialog-container {
  max-height: 500px !important;
}

.hide {
  display: none
}

.mat-form-field {
  padding-bottom: 0;
  width: 100%;
}

mat-radio-button {
  padding-bottom: 20px
}

button:focus {
  outline: none;
}

.text-green {
  color: green;
}

.text-danger {
  color: #ed6a5e !important;
}

.hide {
  display: none
}

.pagination li {

  a {
    font-size: 1.2rem;
  }

  &.active {
    a {
      color: #fff;
      background-color: #F3715A;
    }
  }

  &.disabled {
    cursor: default;
    color: #999;
  }
}

.radio-group-vertical .radio-button {
  margin: 5px 20px 5px 5px;
  display: block
}

.example-radio-button {
  margin: 5px 20px 5px 5px;
}

.line {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px
}

.btn-small {
  line-height: 28px;
}

ul.menu {
  margin: 0px;
  padding: 0px
}

ul.menu li {
  display: inline-block;
  padding: 6px 6px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #f4f4f4
}

ul.menu li:hover {
  background-color: #f9f9f9
}

.done {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
}

.example-form {
  width: 500px;
}

.pointer {
  cursor: pointer
}

.pointer:hover {
  background-color: #f9f9f9
}

.IGNORE-example-full-width {
  width: 100%;
}

.INGORE-example-half-width {
  width: 50%;
}

.input-field {
  margin: 0px 15px 0px 0px
}

.input-full-width {
  width: 100%;
}

.input-half-width {
  width: 100%;
}

.input-w100 {
  width: 100px;
}

.input-w300 {
  width: 300px;
}

.flexbox {
  display: flex;
  align-content: space-between
}

.flexbox .flexitem, .flexbox mat-form-field {
  flex-grow: 1;
  flex: flex-grow
}

.mat-table td .save {
  min-width: auto
}

.mat-table td .delete {
  color: #999;
}

td.light-red {
  background-color: #ffb6bf;
}

td.light-green {
  background-color: #90ffa8;
}

.bg-gold {
  background: linear-gradient(180deg, rgb(253, 218, 134) 0, rgb(225, 157, 60) 100%), rgb(253, 218, 134);
}

.text-gold {
  color: rgb(225, 157, 60);
}

.mat-table td .delete:hover {
  color: red;
  background-color: transparent;
}

.subscription-status {
  background: linear-gradient(180deg, rgb(253, 218, 134) 0, rgb(225, 157, 60) 100%), rgb(253, 218, 134);
  color: white;
}

.mat-table {
  width: 100%;
  box-shadow: rgba(37, 11, 54, .04) 0 2px 0;
  border: 1px solid rgba(0, 0, 0, .07);
}

.mat-table td {
  padding: 2px 3px;
  font-size: 14px;
  vertical-align: top
}

.mat-table th {
  text-align: left;
  border-bottom: 3px solid #f4f4f4
}

.mat-table td {
  border-bottom: 1px solid #f4f4f4
}

.mat-table .mat-table-action {
  text-align: right;
  white-space: nowrap;
}

.mat-table-action .mat-button, .mat-fab, .mat-icon-button, .mat-mini-fab, .mat-raised-button {
  min-width: auto
}

.mat-table-nopad th, .mat-table-nopad td {
  padding: 0px;
  border: 0px none
}

.mat-table-bottom td {
  vertical-align: bottom;
}

.mat-table.mat-table-noborder td, .mat-table.mat-table-noborder th {
  border: 0px none;
}

.mat-table.mat-table-compact td {
  padding: 1px;
  font-size: 14px
}

.mat-table.mat-table-compact th {
  font-size: 14px
}

.mat-list .mat-list-item:hover {
  background-color: #f4f4f4
}

.mat-chip:hover {
  opacity: 0.7;
  box-shadow: 0px 0px 5px 1px #eee;
}

.menu-items .mat-list .mat-list-item.mat-2-line .mat-list-item-content, .mat-nav-list .mat-list-item.mat-2-line .mat-list-item-content {
  height: auto;
  padding: 3px;
  border-bottom: 1px solid #f4f4f4
}

mat-form-field mat-icon.field-icon-right {
  position: absolute;;
  right: 0px;
  top: 0px
}

.mat-list-item {
  height: auto !important;
}

.delete {
  color: #999 !important;

  &:hover {
    background: rgba(255, 138, 138, 0.33) !important;
  }
}

.save {
  color: darkgreen !important;

  &:hover {
    background: lightgreen;
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.mat-checkbox-label {
  display: flex;
}

mat-paginator {
  box-shadow: rgba(37, 11, 54, .04) 0 2px 0;
  border: 1px solid rgba(0, 0, 0, .07);
}

.phoneNumber input{
  height: 60px;
}

.mobileNumber{
  .intl-tel-input {
    width: 100%;
    margin-top: 3px;
  }
  input {
    height: 50px;
  }

}
