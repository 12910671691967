/* $header
 ------------------------------------------*/
mat-toolbar {
  .toolbar-avatar {
    width: 40px;
    height: 40px;
    line-height: 24px;
  }
  .toolbar-avatar img{
    width: 40px;
    border-radius: 50%;
  }
  .notification-label {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: mat-color($warn);
    border: 4px solid mat-color($warn);
    color: white;
    text-align: center;
  }
  &.main-header {
    padding: 0 8px;
    position: relative;
    box-shadow: 0 1px 8px rgba(0,0,0,.3);
    z-index: 9;
    .branding {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 50px;
    }
    .logo {
      display: inline;
      background-image: url(/assets/images/logo.png);
      width: 109px;
      height: 25px;
      -webkit-background-size: 109px 25px;
      background-size: 109px 25px;
      margin: auto;
    }
    .search-bar {
      .search-form {
        background: rgba(255,255,255,1);
        position: relative;
        border-radius: $border-radius-large;
        margin-right: $gutter;
        display: block;
        max-width: 800px;
        input {
          font-size: 1rem;
          padding: .95rem .75rem;
          z-index: 2;
          cursor: text;
          text-indent: 30px;
          border: none;
          background: transparent;
          width: 100%;
          outline: 0;
        }
        .material-icons {
          position: absolute;
          top: 50%;
          left: 10px;
          margin-top: -12px;
          color: rgba(mat-color($foreground, base), 0.87);
        }
      }
    }
  }
}

// Specific height for mobile devices in portrait mode.
@media ($mat-xsmall) and (orientation: portrait) {
  mat-toolbar {
    &.main-header {
      .logo {
        width: 87px;
        height: 20px;
        -webkit-background-size: 87px 20px;
        background-size: 87px 20px;
      }
    }
  }
}

[dir="rtl"] {
  .main-header {
    .branding {
      padding: 0 16px 0 64px;
    }
    .search-bar {
      .search-form {
        .material-icons {
          left: auto;
          right: 10px;
        }
      }
    }
  }
}


